<template>
  <div class="sidebar">
    <el-menu
      class="sidebar-el-menu"
      :default-active="onRoutes"
      :collapse="collapse"
      background-color="#324157"
      text-color="#bfcbd9"
      active-text-color="#20a0ff"
      unique-opened
      router
    >
      <li class="nav-header">
        <img :src="LogoImg" />
      </li>
      <!-- <el-menu-item :index="v.index" v-for="(v, j) in menus" :key="j">
        <i :class="v.icon"></i>
        <span slot="title">{{ v.title }}</span>
      </el-menu-item>-->
      <template v-for="item in menus">
        <template v-if="item.subs">
          <el-submenu :index="item.index" :key="item.index">
            <template slot="title">
              <i :class="item.icon"></i>
              <span slot="title">{{ item.title }}</span>
            </template>
            <template v-for="subItem in item.subs">
              <template v-if="subItem.subs">
                <el-submenu :index="subItem.index" :key="subItem.index">
                  <template slot="title">{{ subItem.title }}</template>
                  <el-menu-item
                    v-for="(threeItem,i) in subItem.subs"
                    :key="i"
                    :index="threeItem.index"
                  >{{ threeItem.title }}</el-menu-item>
                </el-submenu>
              </template>
              <template v-else>
                <el-menu-item :index="subItem.index" :key="subItem.index">{{ subItem.title }}</el-menu-item>
              </template>
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="item.index" :key="item.index">
            <i :class="item.icon"></i>
            <span slot="title">{{ item.title }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "sidemenu", // 侧边栏
  data() {
    return {
      LogoImg:
        "https://i2.vzan.cc/image/liveimg/jpeg/2019/5/5/14421099377ff737ca4faca11c842c8614c890.jpeg",
      collapse: false,
      menus: [
        {
          icon: "el-icon-s-promotion",
          index: "Live",
          title: "活动",
          subs: [
            { index: "/activity", title: "活动列表" },
            { index: "/activity/add", title: "新增活动" }
          ]
        },
        // {
        //   icon: "el-icon-s-check",
        //   index: "/config/rights",
        //   title: "权限管理"
        // },
        {
          icon: "el-icon-s-check",
          index: "/config/admin",
          title: "管理员列表"
        },
        {
          icon: "el-icon-setting",
          index: "/config/settings",
          title: "系统设置"
        }
      ]
    };
  },
  computed: {
    onRoutes() {
      return this.$route.path;
    }
  },
  created() {
    this.$bus.$on("collapse", msg => {
      this.jurisdiction = msg;
      this.collapse = msg;
    });
  }
};
</script>

<style lang="scss" scoped>
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  background-color: rgb(50, 65, 87);
  & > ul {
    height: 100%;
  }
  .nav-header {
    background: #2c3b52;
    padding: 25px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .no-nav-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .el-menu-item,
  .el-submenu {
    .vzaniconfont {
      display: inline-block;
      margin-right: 5px;
      min-width: 24px;
      max-width: 24px;
      text-align: center;
      font-size: 18px;
      color: #bfcbd9;
    }
    .el-submenu__title {
      height: 50px;
      line-height: 50px;
    }
  }
}

.sidebar::-webkit-scrollbar {
  width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
  width: 220px;
}
</style>
